import React from 'react'
import { Link } from 'react-router-dom';
function ServiceOne() {
    return (
        <div>
            <>
                {/* rts service post area  Start*/}
                <div className="rts-service-area rts-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="rts-title-area service text-center">
                                    <p className="pre-title">Our Services</p>
                                    <h2 className="title">High Quality Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid service-main plr--120-service mt--50 plr_md--0 pl_sm--0 pr_sm--0">
                        <div className="background-service row">
                            {/* start single Service */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="service-one-inner one">
                                    <div className="thumbnail">
                                        <img
                                            src="assets/images/service/icon/01.svg"
                                            alt="finbiz_service"
                                        />
                                    </div>
                                    <div className="service-details">
                                        <Link to={'/service-details'}>
                                            <h5 className="title">Data Network Creation</h5>
                                        </Link>
                                        <p className="disc">
                                            Gathering, analyzing, and documenting data for FTTx, MDU, and structured cabling solutions, ensuring efficiency
                                        </p>
                                        <Link
                                            className="rts-read-more btn-primary"
                                            to={'/service-details'}
                                        >
                                            <i className="far fa-arrow-right" />
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* end single Services */}
                            {/* start single Service */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="service-one-inner two">
                                    <div className="thumbnail">
                                        <img
                                            src="assets/images/service/icon/02.svg"
                                            alt="finbiz_service"
                                        />
                                    </div>
                                    <div className="service-details">
                                        <Link to={'/service-details'}>
                                            <h5 className="title">Network Data Configuration</h5>
                                        </Link>
                                        <p className="disc">
                                            Expertly configuring network systems, specializing in Cisco, Juniper, Ruckus, and Fortinet solutions for optimized performance and reliability
                                        </p>
                                        <Link
                                            className="rts-read-more btn-primary"
                                            to={'/service-details'}
                                        >
                                            <i className="far fa-arrow-right" />
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* end single Services */}
                            {/* start single Service */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="service-one-inner three">
                                    <div className="thumbnail">
                                        <img
                                            src="assets/images/service/icon/03.svg"
                                            alt="finbiz_service"
                                        />
                                    </div>
                                    <div className="service-details">
                                        <Link to={'/service-details'}>
                                            <h5 className="title">IT Network Administration</h5>
                                        </Link>
                                        <p className="disc">
                                            Managing, monitoring, and optimizing IT networks for seamless performance, security, and reliability
                                        </p>
                                        <Link
                                            className="rts-read-more btn-primary"
                                            to={'/service-details'}
                                        >
                                            <i className="far fa-arrow-right" />
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* end single Services */}
                        </div>
                        <div className="row">
                            <div className="cta-one-bg col-12">
                                <div className="cta-one-inner">
                                    <div className="cta-left">
                                        <h3 className="title">
                                            <u>Our Mission</u> is to become a trusted partner for our clients
                                        </h3>
                                    </div>
                                    <div className="cta-right">
                                        <Link className="rts-btn btn-white" to="/contactus">
                                            Lets Work Together
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* rts service post area ENd */}
            </>

        </div>
    )
}

export default ServiceOne