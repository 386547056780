import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoadTop from '../components/LoadTop';
import BackToTop from '../components/BackToTop';
import HomeOne from "./HomeOne";
// inner pages
import OurService from "../inner/OurService";
import ServiceTwo from "../inner/ServiceTwo";
import ServiceThree from "../inner/ServiceThree";
import ServiceDetails from "../inner/ServiceDetails";
import Appoinment from "../inner/Appoinment";
import AboutUs from "../inner/AboutUs";
import PricingPlane from "../inner/PricingPlane";
import TestimonialsOne from "../inner/TestimonialsOne";
import Error from "../inner/Error";
import Project from "../inner/Project";
import ProjectDetails from "../inner/ProjectDetails";
import Team from "../inner/Team";
import TeamTwo from "../inner/TeamTwo";
import TeamThree from "../inner/TeamThree";
import TeamFour from "../inner/TeamFour";
import TeamFive from "../inner/TeamFive";
import TeamDetails from "../inner/TeamDetails";
import ContactUs from '../inner/ContactUs';

import portfolio from "../data/portfolio.json";

function RouterPage() {
    return (
        <div>
            <Router>
                <LoadTop />
                <Routes>
                    <Route path="/" element={<HomeOne />}></Route>
                    <Route path="/our-service" element={<OurService />}></Route>
                    <Route path="/service-2" element={<ServiceTwo />}></Route>
                    <Route path="/service-3" element={<ServiceThree />}></Route>
                    <Route path="/service-details" element={<ServiceDetails />}></Route>
                    <Route path="/appoinment" element={<Appoinment />}></Route>
                    <Route path="/about-us" element={<AboutUs />}></Route>
                    <Route path="/pricing-plane" element={<PricingPlane />}></Route>
                    <Route path="/testimonial-style-1" element={<TestimonialsOne />}></Route>
                    <Route path="/404" element={<Error />}></Route>
                    <Route path="/project" element={<Project />}></Route>
                    <Route path="/team" element={<Team />}></Route>
                    <Route path="/team-style-2" element={<TeamTwo />}></Route>
                    <Route path="/team-style-3" element={<TeamThree />}></Route>
                    <Route path="/team-style-4" element={<TeamFour />}></Route>
                    <Route path="/team-style-5" element={<TeamFive />}></Route>
                    <Route path="/team-details" element={<TeamDetails />}></Route>
                    <Route path="/contactus" element={<ContactUs />}></Route>
                    {portfolio.map((data, index) => {
                        return (
                            <Route path={data.link} element={<ProjectDetails index={index} />}></Route>
                        );
                    })}
                </Routes>
                <BackToTop />
            </Router>
        </div>
    )
}

export default RouterPage