import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import SideMenu from './SideMenu';


function HeaderOne() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {/* start header area */}
            <header className={`header--sticky header-one  ${isSticky ? 'sticky' : ''}`}>
                <div className="header-top header-top-one bg-color-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-xl-block d-none">
                                <div className="left">
                                    <div className="mail">
                                        <Link to={'mailto:info@cogencyg.com'}>
                                            <i className="fal fa-envelope" /> Info@Cogencyg.com
                                        </Link>
                                    </div>
                                    <div className="working-time">
                                        <p>
                                            <i className="fal fa-clock" /> Working: Mon - Fri | 9:00 a.m. - 4:30 p.m. ET
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-xl-block d-none">
                                <div className="right">
                                    <ul className="top-nav">
                                        <li>
                                            <Link to={'/about-us'}>Employees</Link>
                                        </li>
                                        <li>
                                            <Link to={'https://vendors.cogencyg.com'}>Vendors</Link>
                                        </li>
                                        <li>
                                            <Link to={'/about-us'}>Clients</Link>
                                        </li>
                                        <li>
                                            <Link to={'/about-us'}>About</Link>
                                        </li>
                                        <li>
                                            <Link to={'/contactus'}>Contact</Link>
                                        </li>
                                    </ul>
                                    <ul className="social-wrapper-one">
                                        <li>
                                            <Link className="mr--0" to={'https://www.linkedin.com/company/cogencygroup'}>
                                                <i className="fab fa-linkedin-in" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-main-one bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="thumbnail">
                                    <Link to={'/'} >
                                        <img src="/assets/images/logo/cogencyg-logo.svg" className='fix-logo' alt="cogencyg-logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className="main-header">
                                    <Nav />
                                    <div className="button-area">
                                        {/*
                                        <button id="search" className="rts-btn btn-primary-alta" onClick={handleSearchClick}>
                                            <i className="far fa-search" />
                                        </button>
                                        */}

                                        <Link
                                            to={'/contactus'}
                                            className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btn"
                                        >
                                            Contact us
                                        </Link>
                                        <button
                                            id="menu-btn"
                                            className="menu rts-btn btn-primary-alta ml--20 ml_sm--5"
                                            onClick={toggleSidebar}>
                                            <img
                                                className="menu-dark"
                                                src="/assets/images/icon/menu.png"
                                                alt="Menu-icon"
                                            />
                                            <img
                                                className="menu-light"
                                                src="/assets/images/icon/menu-light.png"
                                                alt="Menu-icon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <SideMenu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
    )
}

export default HeaderOne