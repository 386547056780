import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function SideMenu({ isSidebarOpen, toggleSidebar }) {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menuhome) => {
    setOpenMenu(openMenu === menuhome ? null : menuhome);
  };

  return (
    <div>
      <div id="side-bar" className={`side-bar ${isSidebarOpen ? "show" : ""}`}>
        <button
          className="close-icon-menu"
          aria-label="Close Menu"
          onClick={toggleSidebar}
        >
          <i className="far fa-times"></i>
        </button>
        {/* inner menu area desktop start */}
        <div className="rts-sidebar-menu-desktop">
          <Link className="logo-1" href="/">
            <img
              className="logo"
              src="/assets/images/logo/cogencyg-logo.svg"
              alt="cogencyg_logo"
            />
          </Link>
          <Link className="logo-2" href="/">
            <img
              className="logo"
              src="/assets/images/logo/cogencyg-logo.svg"
              alt="cogencyg_logo"
            />
          </Link>
          <Link className="logo-3" href="/">
            <img
              className="logo"
              src="/assets/images/logo/cogencyg-logo.svg"
              alt="cogencyg_logo"
            />
          </Link>
          <Link className="logo-4" href="/">
            <img
              className="logo"
              src="/assets/images/logo/cogencyg-logo.svg"
              alt="cogencyg_logo"
            />
          </Link>
          <div className="body d-none d-xl-block">
            <p className="disc">
            For more information, please refer to the details provided below.
            </p>
            <div className="get-in-touch">
              {/* title */}
              <div className="h6 title">Get In Touch</div>
              {/* title End */}
              <div className="wrapper">
                {/* single */}
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <Link href="tel:+19432522202">+1 (943) 252-2202</Link>
                </div>
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <Link href="tel:8332662022">Toll Free (833) 266-2022</Link>
                </div>
                <div className="single">
                  <i className="fa fa-id-badge" />
                  <Link href="#">EIN: 85-2065265</Link>
                </div>
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <Link href="+15615285776">Shipping & Receiving: +1 (561) 528-5776</Link>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-envelope" />
                  <Link href="mailto:info@cogencyg.com">Info@Cogencyg.com</Link>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-map-marker-alt" />
                  <Link href="https://www.google.com/maps/place/Cogency+Group,+LLC/@33.9428798,-84.1616215,17z/data=!3m1!5s0x88f5bcd59828e0fb:0xccb4564751899c19!4m6!3m5!1s0x88f5a35bb6eaf7bf:0xa2da9e21c8f01cc3!8m2!3d33.9428754!4d-84.1590412!16s%2Fg%2F11qprgg2tf?entry=ttu&g_ep=EgoyMDI1MDExNC4wIKXMDSoJLDEwMjExMjMzSAFQAw%3D%3D">
                    4500 Satellite Boulevard Suite 2130, Duluth, GA, (HD)30096
                  </Link>
                </div>
                {/* single ENd */}
              </div>
              <div className="social-wrapper-two menu">
                <Link href="#">
                  <i className="fab fa-whatsapp" />
                </Link>
                <Link href="https://www.linkedin.com/company/cogencygroup">
                  <i className="fab fa-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="body-mobile d-block d-xl-none">
            <nav className="nav-main mainmenu-nav">
              <ul className="mainmenu metismenu" id="mobile-menu-active">
                <li className="menu-item">
                  <Link className="menu-link" href="/">
                    Home
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" href="/aboutus">
                    About us
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" href="/project">
                    Projects
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" href="https://blog.cogencyg.com">
                    Blog
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="social-wrapper-two menu mobile-menu">
              <Link href="#">
                <i className="fab fa-whatsapp" />
              </Link>
              <Link href="https://www.linkedin.com/company/cogencygroup">
                <i className="fab fa-linkedin" />
              </Link>
            </div>
            <Link
              href="/contactus"
              className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu"
            >
              Contact us
            </Link>
          </div>
        </div>
        {/* inner menu area desktop End */}
      </div>
    </div>
  );
}

export default SideMenu;
