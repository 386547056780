import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Core Swiper styles
import { Navigation, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import portfolio from '../../data/portfolio.json';


function GalleryOne() {
    return (
        <div>
            {/* start gallery section */}
            <div className="rts-gallery-area rts-section-gap gallery-bg bg_image">
                <div className="container">
                    <div className="row">
                        <div className="rts-title-area gallery text-start pl_sm--20">
                            <p className="pre-title">Popular Projects</p>
                            <h2 className="title">Our Completed Projects</h2>
                        </div>
                    </div>
                    <div className="row mt--45">
                        <div className="col-12">
                            <div className="swiper mygallery mySwipers">
                                <Swiper
                                    // install Swiper modules
                                    modules={[Navigation, EffectFade, Scrollbar, A11y, Autoplay]}
                                    className="mySwipers"
                                    speed={1500}
                                    effect='fade'
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    loop={true}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                >
                                    {/* TODO: Considerar limitar la cantidad de elementos  */}
                                    {portfolio.map((item, index) => (
                                        <SwiperSlide>
                                        <div className="row g-5 w-g-100">
                                            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                                <div className="thumbnail-gallery">
                                                    <img
                                                        src={item.image}
                                                        alt={item.title}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                                <div className="bg-right-gallery">
                                                    <div className="icon">
                                                        <img
                                                            src="assets/images/gallery/icon/01.svg"
                                                            alt="cogencyg-business-gallery"
                                                        />
                                                    </div>
                                                    <Link to={item.link}>
                                                        <h4 className="title">{item.title}</h4>
                                                    </Link>
                                                    <span>{item.subtitle}</span>
                                                    <p className="disc">{item.summary}</p>
                                                    <Link className="rts-btn btn-primary" to={item.link}>View Project</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    ))}
                                </Swiper>


                                <div className="swiper-button-next" />
                                <div className="swiper-button-prev" />
                                <div className="swiper-pagination" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* start gallery section */}

        </div>
    )
}

export default GalleryOne