
import React, { useEffect } from 'react';
import WOW from 'wow.js';


function AboutOne() {
    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',      // default
            animateClass: '',     // set to empty because we use custom classes
            offset: 0,            // distance to the element when triggering the animation
            mobile: true,         // trigger animations on mobile devices
            live: true            // act on asynchronously loaded content
        });
        wow.init();
    }, []);
    return (
        <div>

            {/* rts about us section start */}
            <div className="rts-about-area rts-section-gap bg-about-sm-shape">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        {/* about left */}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 mt_md--50 mt_sm--50">
                            <div className="rts-title-area">
                                <p className="pre-title">More About Us</p>
                                <h2 className="title">We enable seamless Connectivity Projects Management and Execution for MSO and ISP</h2>
                            </div>
                            <div className="about-inner">
                                <p className="disc">
                                Cogency Group, with over 10 years of expertise, delivers tailored Connectivity Projects Management and Execution for MSO and ISP across industries like Hotels, Education, Government, and Retail, ensuring innovation, efficiency, and excellence.
                                </p>
                                {/* start about success area */}
                                <div className="row about-success-wrapper">
                                    {/* left wrapper start */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">24/7 IT and Telecommunications Support</p>
                                        </div>
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">Highly Skilled Professionals</p>
                                        </div>
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">Comprehensive Technology Solutions</p>
                                        </div>
                                    </div>
                                    {/* left wrapper end */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">Improving Business Connectivity</p>
                                        </div>
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">Tailored IT Strategies</p>
                                        </div>
                                        <div className="single">
                                            <i className="far fa-check" />
                                            <p className="details">Industry-Leading Services</p>
                                        </div>
                                    </div>
                                </div>
                                {/* start about success area */}
                                {/* about founder & get in touch start */}
                                <div className="row about-founder-wrapper align-items-center mt--40">
                                    {/* left area start */}
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_sm--20">
                                        <div className="author-call-option">
                                            <img
                                                className="authore-call"
                                                src="assets/images/about/email.svg"
                                                alt="call_founder"
                                            />
                                            <div className="call-details">
                                                <span>For inquiries</span>
                                                <a href="mailto:info@cogencyg.com">
                                                    <h6 className="title">Info@Cogencyg.com</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* left area end */}
                                    {/* right founder area */}
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_sm--20">
                                        <div className="author-call-option">
                                            <img
                                                className="authore-call"
                                                src="assets/images/about/call.svg"
                                                alt="call_founder"
                                            />
                                            <div className="call-details">
                                                <span>Mon - Fri | 9:00 a.m. - 4:30 p.m. ET</span>
                                                <a href="tel:+18475555555">
                                                    <h6 className="title">+1 (943) 252-2202</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* left founder area */}
                                </div>
                                {/* about founder & get in touch end */}
                            </div>
                        </div>
                        {/* about right */}
                        {/* about-right Start*/}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <div className="about-one-thumbnail">
                                <img
                                    src=" assets/images/about/main/about-01.webp"
                                    alt="about-finbiz"
                                />
                                <img
                                    className="small-img"
                                    src="assets/images/about/main/about-02.webp"
                                    alt="finbiz-small"
                                />
                                <div className="experience">
                                    <div className="left single">
                                        <h2 className="title">10+</h2>
                                        <p className="time">Years</p>
                                    </div>
                                    <div className="right single">
                                        <p className="disc">Of expertise in Network Solutions, FTTH & telecommunications services</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* about-right end */}
                    </div>
                </div>
            </div>
            {/* rts about us section end */}

        </div >
    )
}

export default AboutOne