import React, { useState } from 'react';


import HeaderOne from "../components/header/HeaderOne";

import FooterOne from "../components/footer/FooterOne";
import Breadcrumb from "./Breadcrumb";
import portfolio from "../data/portfolio.json";


function ProjectDetails({ index }) {
    const data = portfolio[index];

    return (
        <div className="">
            <HeaderOne />

            <Breadcrumb title="Portfolio Details" breadcrumbs={data.breadcrumbs} />

            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src="/assets/images/product/saint-augustine-university-proyect-main-cogencyg.webp"
                                    alt="Saint Augustine University Project"
                                />
                                <div className="project-info">
                                    <div className="info-head">
                                        <h5 className="title">Project Information</h5>
                                    </div>
                                    <div className="info-body">
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-user" />
                                            </div>
                                            <div className="info-details">
                                                <span>Prime Contractor:</span>
                                                <h6 className="name">{data.information.primeContractor}</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-layer-group" />
                                            </div>
                                            <div className="info-details">
                                                <span>Beneficiary:</span>
                                                <h6 className="name">{data.information.beneficiary}</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-calendar-alt" />
                                            </div>
                                            <div className="info-details">
                                                <span>Accomplishment:</span>
                                                <h6 className="name">{data.information.accomplishment}</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-map-marker-alt" />
                                            </div>
                                            <div className="info-details">
                                                <span>Year & Location:</span>
                                                <h6 className="name">{data.information.yearLocation}</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--70 mb--50">
                        <div className="col-12">
                            <div className="product-details-main-inner">
                                <span>{data.subtitle}</span>
                                <h3 className="title">{data.title}</h3>
                                {data.paragraphs.map(paragraph => (
                                    <p className="disc">{paragraph}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="row g-5 mb--60">
                        {data.images.map(imageUrl => (
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img src={imageUrl} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row g-5">
                        <div className="col-12">
                            <h3 className="title mb--0">Project Highlights</h3>
                        </div>
                        <div className="fmba-two-columns-container">
                            {data.highlights.map(highlight => (
                                <div className="single-project-details-challenge">
                                    <div className="icon">
                                        <i className="far fa-check-circle" />
                                    </div>
                                    <p className="details">
                                        <strong>{highlight.title}:</strong> {highlight.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}

            <FooterOne />
        </div>
    );
}

export default ProjectDetails