import React, { useState } from "react";
import { Tabs, Tab, TabContent } from "react-bootstrap";

import HeaderOne from "../components/header/HeaderOne";

import { Link } from "react-router-dom";
import FooterOne from "../components/footer/FooterOne";
import Breadcrumb from "./Breadcrumb";

import portfolio from "../data/portfolio.json";

function Project() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Portfolio" }];
  const [selectedOption, setSelectedOption] = useState("all");
  
  return (
    <div className="">
      <HeaderOne />

      <Breadcrumb title="Portfolio" breadcrumbs={breadcrumbs} />

      {/* project section srart */}
      <div className="rts-project-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-button-area-one">
                <Tabs
                  selectedOption={selectedOption}
                  onSelect={(k) => setSelectedOption(k)}
                  id="controlled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="all" title="All Projects" />
                  <Tab eventKey="sc" title="Structured Cabling" />
                  <Tab eventKey="ftth" title="FTTH" />
                  <Tab eventKey="mdu" title="Multi-Dwelling Units (MDU)" />
                </Tabs>
              </div>

              <div className="tab-content-area mt--50 mt_sm--30">
                <TabContent>
                  <div className="row g-5">
                    {portfolio.map((data, index) => {
                      if (
                        selectedOption !== "all" &&
                        data.category !== selectedOption
                      ) {
                        return (
                          <div className="text-center">
                            <div>
                              <i
                                class="fa fa-thumbs-o-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div>We do not have projects in this category</div>
                          </div>
                        );
                      }

                      return (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="rts-product-one">
                            <div className="thumbnail-area">
                              <img src={data.thumbnail} alt={data.title} />
                              <Link
                                className="rts-btn btn-primary rounded"
                                to={data.link}
                              >
                                <i className="far fa-arrow-right" />
                              </Link>
                            </div>
                            <div className="product-contact-wrapper">
                              <span>{data.subtitle}</span>
                              <Link to={data.link}>
                                <h5 className="title">{data.title}</h5>
                              </Link>
                              <p className="disc">{data.summary}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* project section end */}

      <FooterOne />
    </div>
  );
}

export default Project;
